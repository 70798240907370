import {
  ActionType,
} from "../../../../../components/fundamentals/actionables/actionables";
import DownloadIcon from "../../../../../components/fundamentals/icons/download-icon";
import StatusIndicator from "../../../../../components/fundamentals/status-indicator";
import Table from "../../../../../components/fundamentals/table";
import { Flow } from "../../../../../models/Flow";
import DateHelper from "../../../../../models/utils/DateHelper";
import * as FileSaver from 'file-saver';

interface IProps {
  flow: Flow;
}
export default function FlowRow({
  flow
}: IProps) {
  const getActions = (): ActionType[] => [
    {
      label: "Télécharger",
      onClick: () => {
        if(flow.url_file){
          FileSaver.saveAs(flow.url_file, `${flow.generateCsvName()}.csv`);
        }
      },
      icon:
        <DownloadIcon size={20} />
    },
  ];
  
  return (
    <>
      <Table.Row 
        color={"inherit"} 
        draggable={false} 
        forceDropdown={true} 
        actions={getActions()}
      >
        <Table.Cell  className="pr-2" style={{whiteSpace: 'normal', maxWidth: 120, wordWrap: 'break-word'}}>{flow.getName()}</Table.Cell>
        <Table.Cell>
          {DateHelper.dateToDDMMYYYYAndHHMMSS(flow.start_at)}  
        </Table.Cell>
        <Table.Cell>
          {flow.end_at ?
            DateHelper.dateToDDMMYYYYAndHHMMSS(flow.end_at)
            : '-'
          }
        </Table.Cell>
        <Table.Cell>
            <StatusIndicator
              title={flow.getStatus()}
              variant={flow.getStatusVariant()}
            />
        </Table.Cell>
        <Table.Cell>
            {flow.report && flow.report.total ? flow.report.total : '-'}
        </Table.Cell>
        <Table.Cell>
          {flow.report && flow.report.done ? flow.report.done : '-'}
        </Table.Cell>
        <Table.Cell>
          {flow.report && flow.report.updated ? flow.report.updated : '-'}
        </Table.Cell>
        <Table.Cell>
          {flow.report && flow.report.ignored ? flow.report.ignored : '-'}
        </Table.Cell>
        <Table.Cell>
          {flow.report && flow.report.skipped ? flow.report.skipped : '-'}
        </Table.Cell>
      </Table.Row>
    </>
  );
}
