import axios from "axios";
import { Document } from "../models/Document";
import { DataPaginate } from "../models/utils/DataPaginate";
import { getParamsUrl } from "./utils/serviceHelper";

function list(
  page: number,
  client_id: string | null,
  start_date?: string,
  end_date?: string,
  source?: string
) {
  let paramsUrl = getParamsUrl([
    { key: "page", value: page },
    { key: "start_date", value: start_date },
    { key: "end_date", value: end_date },
    { key: "client_id", value: client_id },
    { key: "source", value: source },
  ]);

  return axios
    .get(`${process.env.REACT_APP_API_URL}documents${paramsUrl}`)
    .then((response: any) => {
    
      const data = response.data;
      const dataPaginate = new DataPaginate({
        current_page: data.page,
        last_page: Math.ceil(data.total / 10),
        from: page * 10 - 9,
        to: data.page * 10,
        per_page: 10,
        total: data.total,
        data: data.items,
        next_page_url: data.page < (Math.ceil(data.total / 10)),
        prev_page_url: data.page > 1
      });
     
      dataPaginate.setData(dataPaginate.data.map((item) => new Document(item)));

      console.log(dataPaginate)

      return dataPaginate;
    });
}

function find(id: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}documents/${id}`)
    .then((response) => new Document(response.data));
}

function download(id: string) {
  return `${process.env.REACT_APP_API_URL}documents/${id}/download`;
}

export default {
  list,
  find,
  download,
};
