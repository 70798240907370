import { useEffect, useState } from "react";
import { DataPaginate } from "../../../../../models/utils/DataPaginate";
import Table from "../../../../../components/fundamentals/table";
import TableContainer from "../../../../../components/fundamentals/table-container";
import {
  EFilter,
  IFilter,
} from "../../../../../models/utils/filter/FilterType.interface";
import ClientRow from "./ClientRow";
import clientsService from "../../../../../services/clients.service";
import { Client } from "../../../../../models/Client";
import {getTablePagingState} from "../../../../../services/utils/TableHelper";

export default function ClientTable() {
  const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [tmpSearch, setTmpSearch] = useState<string>();
  const [filters, setFilters] = useState<IFilter[]>();

  // const [columns] = useOrderTableColumn({
  //   setTileView,
  //   setListView,
  //   showList,
  // })

  async function fetchClients() {
    setIsLoading(true);
    setDataPaginate(await clientsService.list(currentPage, search, filters));
    setIsLoading(false);
  }
  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, [currentPage, search, filters]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setSearch(tmpSearch);
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [tmpSearch]);
  // ---- FILTERS ----
  // useEffect(() => {
  //   async function fetchFilters() {
  //     const clients = await clientsService.list(currentPage, search, filters);
  //     const clientsList = clients.data.map((c) => {
  //       return {
  //         label: c.name,
  //         value: c.id,
  //       };
  //     });
  //     setFilters([
  //       {
  //         name: "date",
  //         type: EFilter.DATE,
  //         label: "Date",
  //       },
  //       {
  //         name: "client",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Client",
  //         list: clientsList,
  //       },
  //       {
  //         name: "sell_canal",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Canal de vente",
  //         list: [
  //           {
  //             label: "Canal 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Canal 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //       {
  //         name: "pick_up_address",
  //         type: EFilter.SELECT_MULTIPLE,
  //         label: "Adresse de retrait",
  //         list: [
  //           {
  //             label: "Adresse 1",
  //             value: 1,
  //           },
  //           {
  //             label: "Adresse 2",
  //             value: 2,
  //           },
  //         ],
  //       },
  //     ]);
  //   }
  //   fetchFilters();
  // }, [currentPage, search]);

  return (
    <div>
      <TableContainer
        numberOfRows={10}
        hasPagination
        pagingState={getTablePagingState(dataPaginate!, setCurrentPage, 'résultats')}
      >
        <Table
          enableSearch={true}
          searchValue={tmpSearch}
          handleSearch={(term) => setTmpSearch(term)}
          // filters={filters}
          // onClear={() => {
          //   setCurrentPage(1);
          //   if (filters) {
          //     setFilters(
          //       filters.map((f) => {
          //         f.value = undefined;
          //         return f;
          //       })
          //     );
          //   }
          // }}
          // onFilter={(filters: IFilter[]) => {
          //   setCurrentPage(1);
          //   setFilters(filters);
          // }}
        >
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell>Nom</Table.HeadCell>
              <Table.HeadCell>Siret</Table.HeadCell>
              <Table.HeadCell>Agence de rattachement (ID)</Table.HeadCell>
              <Table.HeadCell>Utilisateurs</Table.HeadCell>
              <Table.HeadCell>Actif</Table.HeadCell>
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>
            {dataPaginate?.data?.map((client: Client, index: number) => (
              <ClientRow
                key={`client_item_${client.id}`}
                client={client}
                index={index + 1}
                refresh={fetchClients}
              />
            ))}
          </Table.Body>
        </Table>
      </TableContainer>
    </div>
  );
}
