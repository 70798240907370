import * as Portal from "@radix-ui/react-portal";
import React from "react";

interface IProps{
    title?: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    footerClassname?: string;
    onClose: () => void;
    isLarge?: boolean
}
export default function ModalV2({title, content, footer, onClose, isLarge = true, footerClassname=''}: IProps){
    const portalRef = React.useRef(null);
    
    return (
        <Portal.Portal ref={portalRef}>
            <div className="fixed bg-grey-90/40 z-50 grid top-0 left-0 right-0 bottom-0 place-items-center overflow-y-auto">
                <div role="dialog" aria-describedby="radix-:r6:" aria-labelledby="radix-:r5:" className="bg-grey-0 min-w-modal rounded-rounded overflow-x-hidden" style={{maxHeight: '737px', pointerEvents: 'auto'}}>
                    <div className="inter-base-regular h-full">
                        {title && <div className="px-7 pt-5 flex flex-row justify-between">
                            <h1 className="inter-xlarge-semibold mb-xsmall">{title}</h1>
                            <div className="flex justify-end">
                                <button className="text-grey-50 cursor-pointer" onClick={() => onClose()}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div> }
                        <div className={`px-7 pt-5 overflow-y-auto pb-7 ${isLarge ? ' w-largeModal' : '!py-large'}`} style={{maxHeight: '570px'}}>
                            <div className="flex flex-col gap-y-6">
                                <div className="flex items-center gap-x-base">
                                    <div className="w-full">
                                        {content}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {footer && <div className={`px-7 bottom-0 pb-5 flex w-full border-t border-grey-20 pt-4 ${footerClassname}`}>
                            {footer}
                        </div>}
                    </div>
                </div>
            </div>
        </Portal.Portal>
    )
}