import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import Actionables, {
  ActionType,
} from "../../../../components/fundamentals/actionables/actionables";
import ChevronDownLightIcon from "../../../../components/fundamentals/icons/chevron-down-light";
import ChevronRightLightIcon from "../../../../components/fundamentals/icons/chevron-right-light";
import DragDropIcon from "../../../../components/fundamentals/icons/DragDropIcon";
import EditIcon from "../../../../components/fundamentals/icons/edit-icon";
import Spinner from "../../../../components/fundamentals/spinner";
import StatusIndicator from "../../../../components/fundamentals/status-indicator";
import StatusSelector from "../../../../components/fundamentals/status-selector";
import Table from "../../../../components/fundamentals/table";
import { IitemDrag } from "../../../../hooks/use-draggable";
import { Category } from "../../../../models/Category";
import categoryService from "../../../../services/category.service";
import productsService from "../../../../services/products.service";
import ModalCategory from "../products-page/modal-category/ModalCategory";

interface IPropsCateg {
  category: Category;
  index: number;
  dragOverItem?: IitemDrag | null;
  onDragStart: (e: any) => void;
  onDragEnter: (e: any) => void;
  onDragEnd: (e: any) => void;
  refresh: () => void;
}
export function CategoryRow({
  category,
  index,
  dragOverItem,
  onDragStart,
  onDragEnter,
  onDragEnd,
  refresh,
}: IPropsCateg) {
  const [isLoadingVisibility, setIsLoadingVisibility] = useState<boolean>();

  const isDraggingOver = useMemo(
    () => dragOverItem && dragOverItem.position === index,
    [dragOverItem, index]
  );

  const updateVisibility = async () => {
    try {
      setIsLoadingVisibility(true);
      await categoryService.update(category.id, {
        enable: !!!category.enable,
        order: category.order,
        image_url: category.image_url,
      });
      await refresh();
      setIsLoadingVisibility(false);
    } catch (error) {
      setIsLoadingVisibility(false);
      console.log(error);
    }
  };
  return (
    <Table.Row
      linkTo={`/categories/${category.id}`}
      color={"inherit"}
      draggable
      onDragStart={(e) => onDragStart(e)}
      onDragEnter={(e) => onDragEnter(e)}
      onDragEnd={onDragEnd}
      className={isDraggingOver ? "bg-stone-100" : ""}
    >
      <Table.Cell className="cursor-grab">
        <DragDropIcon />
      </Table.Cell>
      <Table.Cell className="first-letter:uppercase lowercase">{category.name}</Table.Cell>
      <Table.Cell>
          {category.nb_products ?? "-"}
      </Table.Cell>
      <Table.Cell>
        <button>
          {!isLoadingVisibility ? (
            <StatusSelector
              isActive={category.enable}
              activeState="Visible"
              inactiveState="Non visible"
              onChange={updateVisibility}
            />
          ) : (
            <Spinner size="small" variant="secondary" />
          )}
        </button>
      </Table.Cell>
    </Table.Row>
  );
}

export enum EStatusArrowCategoryRow {
  HIDDEN = "hidden",
  OPEN = "open",
  CLOSE = "close",
}

interface IPropsSubCateg {
  category: Category;
  index: number;
  dragOverItem?: IitemDrag | null;
  dragItem?: IitemDrag | null;
  dragStart: (e: any) => void;
  dragEnter: (e: any) => void;
  dragEnd: () => void;
  handleClick?: () => void;
  statusArrow: EStatusArrowCategoryRow;
  refresh: () => void;
}

export function SubCategoryRow({
  category,
  index,
  dragOverItem,
  dragItem,
  dragStart,
  dragEnter,
  dragEnd,
  handleClick,
  statusArrow,
  refresh,
}: IPropsSubCateg) {
  const [isLoadingVisibility, setIsLoadingVisibility] = useState<boolean>();

  const highlitghtLine = useMemo(
    () =>
      dragOverItem &&
      dragItem &&
      dragOverItem.item.id === category.id &&
      dragItem.position !== index &&
      dragItem.item.level === category.level &&
      dragItem.item.parent_id === dragOverItem.item.parent_id,
    [index, dragItem, dragOverItem, category]
  );

  const couldDropHere = useMemo(
    () =>
      dragOverItem &&
      dragItem &&
      dragOverItem.position !== dragItem.position &&
      dragItem.item.level === dragOverItem.item.level &&
      dragItem.item.parent_id === dragOverItem.item.parent_id,
    [dragItem, dragOverItem]
  );

  const getMarginLevel = () => {
    switch (category.level) {
      case 3:
        return "pl-4";
      case 4:
        return "pl-8";
      case 5:
        return "pl-12";
      case 6:
        return "pl-16";
    }
  };

  const updateVisibility = async () => {
    try {
      setIsLoadingVisibility(true);
      await categoryService.update(category.id, {
        enable: !!!category.enable,
        order: category.order,
        image_url: category.image_url,
      });
      await refresh();
      setIsLoadingVisibility(false);
    } catch (error) {
      setIsLoadingVisibility(false);
      console.log(error);
    }
  };

  return (
    <Table.Row
      color={"inherit"}
      draggable
      onDragStart={(e) => dragStart(e)}
      onDragEnter={(e) => dragEnter(e)}
      onDragEnd={() => {
        couldDropHere && dragEnd();
      }}
      onClick={() =>
        statusArrow !== EStatusArrowCategoryRow.HIDDEN &&
        handleClick &&
        handleClick()
      }
      className={`${highlitghtLine ? "bg-stone-100" : ""} cursor-pointer`}
      linkTo={`/categories/${category.id}`}
    >
      <Table.Cell className="w-12 cursor-grab">
        <DragDropIcon />
      </Table.Cell>
      <Table.Cell className={`flex items-center ${getMarginLevel()}`}>
        {statusArrow === EStatusArrowCategoryRow.CLOSE ? (
          <ChevronRightLightIcon className="w-4 mr-4" />
        ) : statusArrow === EStatusArrowCategoryRow.OPEN ? (
          <ChevronDownLightIcon className="w-4 mr-4" />
        ) : (
          <></>
        )}
        <span
          className={clsx("first-letter:uppercase lowercase", {
            "pl-8": statusArrow === EStatusArrowCategoryRow.HIDDEN,
          })}
        >
          {category.name}
        </span>
      </Table.Cell>
      <Table.Cell>
         {category.nb_products ?? "-"}
      </Table.Cell>
      <Table.Cell>
        {!isLoadingVisibility ? (
          <StatusSelector
            isActive={category.enable}
            activeState="Visible"
            inactiveState="Non visible"
            onChange={updateVisibility}
          />
        ) : (
          <Spinner size="small" variant="secondary" />
        )}
      </Table.Cell>
    </Table.Row>
  );
}

const useNbProducts = (id: number) => {
  const [nbProducts, setNbProducts] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchNbProducts = async () => {
      try {
        setIsLoading(true);
        const products = await productsService.list(null, null, null, id);
        setNbProducts(products.total);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchNbProducts();
  }, []);

  return { nbProducts, isLoading };
};
