import { Price } from "./Price";
import { Stock } from "./Stock";

export class Product {

  public id: number;
  public name: string;
  public compl_name?: string;
  public ean: string;
  public description: string;
  public code_product: string;
  public image_url?: string;
  public img_url_min?: string;
  public last_date_update: Date;
  public universe_id: number;
  public sub_universe_id: number;
  public created_at: Date;
  public updated_at: Date;
  public stock?:Stock[];
  public price?:Price;
  public is_disabled: boolean;
  public multiplier: number;
  public details: {label: string, value: string}[];
  public pim_sync_at: Date;

  constructor(data: any){
    this.id = data.id;
    this.name = data.name;
    this.compl_name = data.compl_name;
    this.ean = data.ean;
    this.description = data.description;
    this.code_product = data.code_product;
    this.image_url = data.image_url;
    this.img_url_min = data.img_url_min;
    this.last_date_update = new Date(data.last_date_update);
    this.universe_id = data.universe_id;
    this.sub_universe_id = data.sub_universe_id;
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
    this.is_disabled = data.is_disabled == true ? true : false;
    this.stock = data.stock;
    this.price = data.price;
    this.multiplier = data.multiplier;
    this.details = data.details;
    
    if(data.price){
      this.price = new Price(data.price)
    }
    if(data.stock){
        this.stock = data.stock.map((stock:any) => new Stock(stock))
    }
    this.pim_sync_at = new Date(data.pim_sync_at)
  }

  totalStock(){
    if (this.stock) {
      const sum = this.stock.reduce((accumulator, value) => {
        return accumulator + value.stock;
      }, 0);
      return sum
    }
    return
  }
}
