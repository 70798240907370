import { axiosStrapiApi } from "./base-api.service";

async function list() {
    const res = await axiosStrapiApi().get(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/agences?populate[0]=image&filters[enable][$eq]=true`
    );

    return res.data;
}

async function get(slug: string) {
    const res = await axiosStrapiApi().get(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/agences?filters[slug][$eq]=${slug}&pagination[page]=1&pagination[pageSize]=1&populate[0]=image&filters[enable][$eq]=true`
    );

    return res.data.data;
}

async function getById(id: string) {
    const res = await axiosStrapiApi().get(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/agences?filters[agency_id][$eq]=${id}&pagination[page]=1&pagination[pageSize]=1&populate[0]=image&filters[enable][$eq]=true`
    );

    return res.data.data;
}

export default {
    list,
    get,
    getById,
};
