export class Agency {
  address: string;
  agency_id: string;
  city: string;
  emails_notification: string;
  name: string;
  postal_code: string;


  constructor(data: any) {
    this.address = data.address;
    this.agency_id = data.agency_id;
    this.city = data.city;
    this.emails_notification = data.emails_notification;
    this.name = data.name;
    this.postal_code = data.postal_code;
  }
}
