export class TimeTableAgencieStrapi {
    public id: number;
    public day: Day;
    public start_at: Date;
    public end_at: Date;
    public break_start: Date | null;
    public break_end: Date | null;

    constructor(data: any) {
        this.id = data.id;
        this.day = data.day;
        this.start_at = new Date();
        data.start_at.split(":")[0] && this.start_at.setHours(data.start_at.split(":")[0]);
        data.start_at.split(":")[1] && this.start_at.setMinutes(data.start_at.split(":")[1]);
        data.start_at.split(":")[2] && this.start_at.setSeconds(data.start_at.split(":")[2]);
        this.end_at = new Date();
        data.end_at.split(":")[0] && this.end_at.setHours(data.end_at.split(":")[0]);
        data.end_at.split(":")[1] && this.end_at.setMinutes(data.end_at.split(":")[1]);
        data.end_at.split(":")[2] && this.end_at.setSeconds(data.end_at.split(":")[2]);
        if (data.break_start) {
            this.break_start = new Date();
            data.break_start.split(":")[0] && this.break_start.setHours(data.break_start.split(":")[0]);
            data.break_start.split(":")[1] && this.break_start.setMinutes(data.break_start.split(":")[1]);
            data.break_start.split(":")[2] && this.break_start.setSeconds(data.break_start.split(":")[2]);
        } else {
            this.break_start = null;
        }
        if (data.break_end) {
            this.break_end = new Date();
            data.break_end.split(":")[0] && this.break_end.setHours(data.break_end.split(":")[0]);
            data.break_end.split(":")[1] && this.break_end.setMinutes(data.break_end.split(":")[1]);
            data.break_end.split(":")[2] && this.break_end.setSeconds(data.break_end.split(":")[2]);
        } else {
            this.break_end = null;
        }
    }

    getStartAtLabel() {
        const hours = this.start_at.getHours() < 10 ? `0${this.start_at.getHours()}` : this.start_at.getHours();
        const minutes = this.start_at.getMinutes() < 10 ? `0${this.start_at.getMinutes()}` : this.start_at.getMinutes();

        let breakString;
        if (this.break_start && this.break_end) {
            const hoursBreak =
                this.break_start.getHours() < 10 ? `0${this.break_start.getHours()}` : this.break_start.getHours();
            const minutesBreak =
                this.break_start.getMinutes() < 10
                    ? `0${this.break_start.getMinutes()}`
                    : this.break_start.getMinutes();
            breakString = ` - ${hoursBreak}:${minutesBreak}`;
        } else {
            return "Fermé";
        }
        return `${hours}:${minutes}${breakString ?? ""}`;
    }

    getEndAtLabel() {
        const hours = this.end_at.getHours() < 10 ? `0${this.end_at.getHours()}` : this.end_at.getHours();
        const minutes = this.end_at.getMinutes() < 10 ? `0${this.end_at.getMinutes()}` : this.end_at.getMinutes();

        let breakString;
        if (this.break_start && this.break_end) {
            const hoursBreak =
                this.break_end.getHours() < 10 ? `0${this.break_end.getHours()}` : this.break_end.getHours();
            const minutesBreak =
                this.break_end.getMinutes() < 10 ? `0${this.break_end.getMinutes()}` : this.break_end.getMinutes();
            breakString = `${hoursBreak}:${minutesBreak} - `;
        } else {
            return "Fermé";
        }

        return `${breakString ?? ""}${hours}:${minutes}`;
    }
}

export enum Day {
    Sunday = "sunday",
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
}

export const arrayOfDayWeekForScreen = [
    {
        indexOfDay: 1,
        name: Day.Monday,
    },
    {
        indexOfDay: 2,
        name: Day.Tuesday,
    },
    {
        indexOfDay: 3,
        name: Day.Wednesday,
    },
    {
        indexOfDay: 4,
        name: Day.Thursday,
    },
    {
        indexOfDay: 5,
        name: Day.Friday,
    },
    {
        indexOfDay: 6,
        name: Day.Saturday,
    },
    {
        indexOfDay: 0,
        name: Day.Sunday,
    },
];
