import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../../../components/fundamentals/back-button";
import Section from "../../../../components/fundamentals/section";
import Spinner from "../../../../components/fundamentals/spinner";
import Table from "../../../../components/fundamentals/table";
import TableContainer from "../../../../components/fundamentals/table-container";
import { useDraggable } from "../../../../hooks/use-draggable";
import { Category } from "../../../../models/Category";
import { DataPaginate } from "../../../../models/utils/DataPaginate";
import categoryService from "../../../../services/category.service";
import {
  EStatusArrowCategoryRow,
  SubCategoryRow,
} from "../category-table/CategoryRow";
import ModalCategoryEditImage from "./modal-category-edit-image/ModalCategoryEditImage";

export enum ETypeModalEdit {
  PICTO = "picto",
  PHOTO = "photo",
}

function CategoryDetailPage() {
  let { id } = useParams();
  const [subCategories, setSubCategories] = useState<Array<Category>>([]);
  const [subCategToShow, setSubCategToShow] = useState<Array<string>>([]);
  const [categ, setCateg] = useState<Category>();
  const [loading, setLoading] = useState<boolean>(true);
  const [openModalEditType, setOpenModalEditType] =
    useState<ETypeModalEdit | null>(null);
  const { dragStart, dragEnter, clear, dragOverItem, dragItem } =
    useDraggable();

  async function fetchSubCategories(activeLoader = true) {
    try {
      activeLoader && setLoading(true);
      if (id) {
        const category = await categoryService.find(id);
        setSubCategories(
          await categoryService.list(null, null, null, id, category.level + 1, true)
        );
        setCateg(category);
      }
      activeLoader && setLoading(false);
    } catch (e) {
      activeLoader && setLoading(false);
    }
  }

  useEffect(() => {
    fetchSubCategories();
    // eslint-disable-next-line
  }, [id]);

  const getStatusArrow = (categ: Category, hasChildren: boolean) => {
    return !hasChildren
      ? EStatusArrowCategoryRow.HIDDEN
      : subCategToShow.includes(categ.id)
      ? EStatusArrowCategoryRow.OPEN
      : EStatusArrowCategoryRow.CLOSE;
  };

  const getNestedSubCateg = (currCateg: Category) => {
    let elems: any[] = [];
    if (subCategToShow.includes(currCateg.id) && subCategories) {
      const children = subCategories
        .filter(
          (sub) =>
            sub.parent_id === currCateg.id && sub.level === currCateg.level + 1
        )
        .sort(sortOrderCatgegories);

      elems = children.map((child: Category, index: number) => {
        const hasChildren = subCategories.filter(
          (sub) => sub.parent_id === child.id && sub.level === child.level + 1
        );

        return (
          <React.Fragment key={`child_item_${child.id}`}>
            <SubCategoryRow
              index={index}
              category={child}
              dragOverItem={dragOverItem}
              dragItem={dragItem}
              dragStart={(e: any) =>
                dragStart(e, {
                  position: child.order,
                  item: child,
                })
              }
              dragEnter={(e: any) =>
                dragEnter(e, {
                  position: child.order,
                  item: child,
                })
              }
              dragEnd={handleDropItem}
              handleClick={() =>
                setSubCategToShow(
                  !subCategToShow.includes(child.id)
                    ? (prev) => [...prev, child.id]
                    : subCategToShow.filter((sub) => sub !== child.id)
                )
              }
              statusArrow={getStatusArrow(child, hasChildren.length > 0)}
              refresh={() => fetchSubCategories(false)}
            />
            {getNestedSubCateg(child)}
          </React.Fragment>
        );
      });
    }
    return elems;
  };

  const handleDropItem = async () => {
    clear();
    if (dragItem && dragOverItem) {
      const { item: itemFrom } = dragItem;
      const { position: posTarget } = dragOverItem;
      try {
        await categoryService.update(itemFrom.id, {
          enable: itemFrom.enable,
          img_base64: itemFrom?.img_base64,
          order: posTarget,
        });

        fetchSubCategories(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const sortOrderCatgegories = (x: Category, y: Category) => {
    if (x.order > y.order) {
      return 1;
    }
    if (x.order < y.order) {
      return -1;
    }
    return 0;
  };

  const filterDatasByLevel = useCallback(
    (lvl: number) => {
      if (subCategories && subCategories.length > 0) {
        return subCategories
          .filter((d) => d.level === lvl)
          .sort(sortOrderCatgegories);
      }
      return [];
    },
    [subCategories]
  );

  const buttonOpenEditIcon = (type: ETypeModalEdit) => (
    <button
      className="btn btn-secondary btn-small"
      onClick={() => setOpenModalEditType(type)}
    >
      Editer
    </button>
  );


  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <div className="pb-5xlarge">
          <BackButton
            path={categ?.level === 1 ? "/products?view=catégories" : `/categories/${categ?.parent_id}`}
            label="Retour aux catégories"
            className="mb-xsmall"
          />
            <div className="grid grid-cols-12 gap-x-base">
          {!loading && subCategories && subCategories.length > 0 && (
              <div className="col-span-8 flex flex-col gap-y-xsmall">
                <Section title={`Catégorie '${categ?.name}'`} forceDropdown>
                  <div className="mt-8">
                    <TableContainer numberOfRows={10} isLoading={loading}>
                      <Table enableSearch={false}>
                        <>
                          <Table.Head>
                            <Table.HeadRow>
                              <Table.HeadCell></Table.HeadCell>
                              <Table.HeadCell>
                                Nom de la catégoorie
                              </Table.HeadCell>
                              <Table.HeadCell>
                                Nombre de produits
                              </Table.HeadCell>
                              <Table.HeadCell>Visibilité</Table.HeadCell>
                            </Table.HeadRow>
                          </Table.Head>
                          <Table.Body>
                            {subCategories.map(
                              (category: Category, index: number) => {
                                const hasChildren = subCategories.filter(
                                  (sub) =>
                                    sub.parent_id === category.id &&
                                    sub.level === category.level + 1
                                );
                                return (
                                  <React.Fragment
                                    key={`category_item_${category.id}`}
                                  >
                                    <SubCategoryRow
                                      index={index}
                                      category={category}
                                      dragOverItem={dragOverItem}
                                      dragItem={dragItem}
                                      dragStart={(e: any) =>
                                        dragStart(e, {
                                          position: category.order,
                                          item: category,
                                        })
                                      }
                                      dragEnter={(e: any) =>
                                        dragEnter(e, {
                                          position: category.order,
                                          item: category,
                                        })
                                      }
                                      dragEnd={handleDropItem}
                                      handleClick={() =>
                                        setSubCategToShow(
                                          !subCategToShow.includes(category.id)
                                            ? (prev) => [...prev, category.id]
                                            : subCategToShow.filter(
                                                (sub) => sub !== category.id
                                              )
                                        )
                                      }
                                      statusArrow={getStatusArrow(
                                        category,
                                        hasChildren.length > 0
                                      )}
                                      refresh={() => fetchSubCategories(false)}
                                    />
                                    {getNestedSubCateg(category)}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Table.Body>
                        </>
                      </Table>
                    </TableContainer>
                  </div>
                </Section>
              </div>
          )}
            {!loading && <div className="flex flex-col col-span-4 gap-y-xsmall">
              <Section
                title={"Picto"}
                forceDropdown
                customActions={buttonOpenEditIcon(ETypeModalEdit.PICTO)}
              >
                <div className="flex flex-col gap-y-3 mt-4">
                  <div className="aspect-square flex items-center justify-start h-20 w-20">
                    <img
                      src={
                        categ?.picto_url
                          ? categ.picto_url
                          : "/images/placeholder-category.svg"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "/images/placeholder-category.svg";
                      }}
                      alt={`Alt`}
                      className="object-contain rounded-rounded w-full h-full"
                    />
                  </div>
                </div>
              </Section>
              <Section
                title={"Photo"}
                forceDropdown
                customActions={buttonOpenEditIcon(ETypeModalEdit.PHOTO)}
              >
                <div className="flex flex-col gap-y-3 mt-4">
                  <div className="aspect-square flex items-center justify-start h-20 w-20">
                    <img
                      src={
                        categ?.image_url
                          ? categ.image_url
                          : "/images/placeholder-category.svg"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "/images/placeholder-category.svg";
                      }}
                      alt={`Alt`}
                      className="object-contain rounded-rounded w-full h-full"
                    />
                  </div>
                </div>
              </Section>
            </div>
            }
          </div>
        </div>
        {loading && <Spinner size="large" variant="secondary" />}
        {/* {!loading && filterDatasByLevel(2)?.length === 0 && <NotFound />} */}
      </div>
      {openModalEditType && categ && (
        <ModalCategoryEditImage
          onClose={() => setOpenModalEditType(null)}
          type={openModalEditType}
          category={categ}
          refreshCategory={async () =>
            id && setCateg(await categoryService.find(id))
          }
        />
      )}
    </div>
  );
}

export default CategoryDetailPage;
