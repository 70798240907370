import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import TemplateLogin from "../../../../components/templates/TemplateLogin/TemplateLogin"
import LogoIcon from "../../../../components/fundamentals/icons/logo-icon"
import SigninInput from "../../../../components/form/input-signin"
import Button from "../../../../components/fundamentals/button"
import useNotification from "../../../../hooks/use-notification"
import authService from "../../../../services/auth.service"


type FormValues = {
    email: string
  }
  

export default function AskPasswordReset(){
    const { register, handleSubmit, reset } = useForm<FormValues>()
    const [isLoading, setIsLoading] = useState(false)
    const notificationToast = useNotification();
    const [emailSent, setEmailSent] = useState<string | null>()

    const onSubmit = async (values: FormValues) => {
        setIsLoading(true);
        try{
            await authService.resetPassword(values.email)
        } catch(e){
            console.log(e)
        } finally{
            setEmailSent(values.email);
            reset();
            setIsLoading(false);
        }
        
    }

    useEffect(() => {
        console.log(emailSent)
    }, [])

    return (
        <TemplateLogin> 
            <div className="flex h-full w-full items-center justify-center">
                <div
                className={
                    "flex min-h-[450px] w-[640px] bg-grey-0 rounded-rounded justify-center transition-['min-height'] duration-300"
                }
                >
                    <div className="flex flex-col pt-12 w-full px-[120px] items-center">
                        
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col items-center">

                            <LogoIcon />
                            <span className="inter-xlarge-semibold mt-4 text-grey-90 text-center">
                                Réinitialisation du mot de passe
                            </span>
                            <span className="inter-base-regular text-grey-50 mb-xlarge text-center">
                                Après avoir compléter le formulaire, vous recevrez les instructions de réinitialisation de mot de passe par email
                            </span>
                            
                            <SigninInput
                                placeholder="Entrez votre email..."
                                {...register("email", { required: true })}
                                autoComplete="email"
                                type="email"
                            />
                           
                            <Button
                                className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                                variant="primary"
                                size="large"
                                type="submit"
                                loading={isLoading}
                            >
                                Envoyer
                            </Button>
                            {
                                emailSent && <span className="mt-3 inter-base-regular text-grey-50 mb-xlarge text-center">
                                    L'email avec les instructions été envoyé à {emailSent}
                                </span>
                            }
                        </div>
                    </form>
                    
                    </div>
                </div>
            </div>
        </TemplateLogin>
    )
}