import axios from "axios";
import { Category } from "../models/Category";
import { DataPaginate } from "../models/utils/DataPaginate";
import { getParamsUrl } from "./utils/serviceHelper";

function list(
  page?: number | null,
  search?: string | null,
  enable?: boolean | null,
  parent_id?: string | null,
  level?: number | null,
  all_children?: boolean | null
) {
  let paramsUrl = getParamsUrl([
    { key: "page", value: page },
    { key: "search", value: search },
    { key: "enable", value: enable },
    { key: "parent_id", value: parent_id },
    { key: "level", value: level },
    { key: "all_children", value: all_children },
  ]);

  return axios
    .get(`${process.env.REACT_APP_API_URL}categories${paramsUrl}`)
    .then((response: any) => {
      return response.data.map((item: any) => new Category(item));
    });
}

function create(body: any) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}categories`, body)
    .then((response) => new Category(response.data));
}

function update(id: any, body: any) {
  return axios
    .put(`${process.env.REACT_APP_API_URL}categories/${id}`, body)
    .then((response) => new Category(response.data));
}

function destroy(id: any) {
  return axios.delete(`${process.env.REACT_APP_API_URL}categories/${id}`);
}

function find(id: string) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}categories/${id}`)
    .then((response) => new Category(response.data));
}

export default {
  list,
  create,
  update,
  destroy,
  find,
};
