import React from "react"
import IconProps from "../types/icon-type"

const Ca2eIcon: React.FC<IconProps> = ({
  size = "50",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >

    </svg>

  )
}

export default Ca2eIcon
