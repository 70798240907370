import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Actionables, { ActionType } from "../../../../../components/fundamentals/actionables/actionables";
import Checkbox from "../../../../../components/fundamentals/checkbox";
import DuplicateIcon from "../../../../../components/fundamentals/icons/duplicate-icon";
import EditIcon from "../../../../../components/fundamentals/icons/edit-icon";
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon";
import ImagePlaceholder from "../../../../../components/fundamentals/image-placeholder/image-placeholder";
import Spinner from "../../../../../components/fundamentals/spinner";
import Table from "../../../../../components/fundamentals/table";
import useImperativeDialog from "../../../../../hooks/use-imperative-dialog";
import { Price } from "../../../../../models/Price";
import { Product } from "../../../../../models/Product";
import { Stock } from "../../../../../models/Stock";
import productsService from "../../../../../services/products.service";
import { columnProduct } from "./ProductTable";
import StatusSelector from "../../../../../components/fundamentals/status-selector";
import useNotification from "../../../../../hooks/use-notification";
import StatusIndicator from "../../../../../components/fundamentals/status-indicator";
import DateHelper from "../../../../../models/utils/DateHelper";

interface IProps{
    product: Product
    columns: columnProduct[],
    selected?: Product[],
    setSelected?: (products: Product[]) => void
    refreshProductsData: () => Promise<void>
}
export default function ProductRow({product, columns, selected, setSelected, refreshProductsData}: IProps){
    const navigate = useNavigate();
    const dialog = useImperativeDialog();
    const notificationToast = useNotification();

    const [loading, setLoading] = useState(true)
    const [isVisibilityChanging, setIsVisibilityChanging] = useState<boolean>(false);
    const [price, setPrice] = useState<Price>();
    const [stocks, setStocks] = useState<Stock[]>();
    console.log(selected)
    useEffect(()=> {
        const fetchAll = async () => {
            setPrice(await productsService.findProductPrice(product.id));
            setStocks(await productsService.findProductStock(product.id));
            setLoading(false)
        }
        fetchAll();
    }, [])

    async function handleDelete(product: Product){
        const shouldDelete = await dialog({
            heading: "Delete Product",
            text: "Are you sure you want to delete this product?",
          })
      
          if (shouldDelete) {
            console.log('TODO DELETE')
          }
    }

    function copyProduct(product: Product){
        console.log('todo', product);
    }


    const getActions = (product: Product): ActionType[] => [
        {
          label: "Modifier",
          onClick: () => navigate(`/products/${product.id}`),
          icon: <EditIcon size={20} />,
        },
        {
            label: "Duppliquer",
            onClick: () => copyProduct(product),
            icon: <DuplicateIcon size={20} />,
          },
          {
            label: "Supprimer",
            variant: "danger",
            onClick: () => handleDelete(product),
            icon: <TrashIcon size={20} />,
          },
    ];

    function toggle(product: Product) {
        if (selected && setSelected) {
            if (selected?.some(sel => sel.id === product.id)) {
                setSelected(selected.filter(sel => sel.id != product.id))
            } else {
                setSelected([...selected, product])
            }
        }
    }

    const changeVisibility = useCallback(async () => {
        try {
            setIsVisibilityChanging(true);

            await productsService.changeVisibility(product.id, product.is_disabled);

            await refreshProductsData();
        } catch (e: any) {
            notificationToast("Erreur", e.message, "error");
        } finally {
            setIsVisibilityChanging(false);
        }
    }, [product]);

    return (
        <Table.Row linkTo={columns.find(col => col === columnProduct.ACTION) ? `/products/${product.id}` : undefined}  color={"inherit"} draggable={false} >
            {columns.map((col: columnProduct, index) => {
                if (col === columnProduct.SELECT) {
                    return (
                        <Table.Cell key={`product-row-col-cell-select-${index}`}>
                            <Checkbox label={undefined} checked={selected?.some(sel => sel.id === product.id)} onChange={() => toggle(product)}/>
                        </Table.Cell>
                    )
                } else if (col === columnProduct.NAME) {
                    return (
                        <Table.Cell key={`product-row-col-cell-name-${index}`}>
                            <div className="flex items-center">
                                <div className="h-[40px] w-[40px] my-1.5 flex items-center mr-4">
                                    {product.img_url_min ? (
                                    <img
                                        src={product.img_url_min}
                                        className="h-full object-cover rounded-soft"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "/images/placeholder-product.svg";
                                        }}
                                    />
                                    ) : (
                                    <ImagePlaceholder />
                                    )}
                                </div>
                                {product.name}
                            </div>
                        </Table.Cell>
                    )
                } else if (col === columnProduct.PRICE) {
                    return (
                        <Table.Cell key={`product-row-col-cell-price-${index}`}>{price && !loading ? `${price.getPriceTotalHTFormat()} €` : loading ? <Spinner size="small" variant="secondary" /> : ''}</Table.Cell>
                    )
                } else if (col === columnProduct.EAN) {
                    return (
                        <Table.Cell key={`product-row-col-cell-ean-${index}`}>{product.ean}</Table.Cell>
                    )
                } else if (col === columnProduct.STOCK) {
                    return (
                        <Table.Cell key={`product-row-col-cell-stock-${index}`}>
                            {stocks && stocks.length > 0 && !loading
                                ? <div className="text-center">{productsService.getTotalStock(stocks)}</div>
                                : loading
                                    ? <Spinner size="small" variant="secondary" />
                                    : <div className="text-center">N/A</div>}
                        </Table.Cell>
                    )
                } else if (col === columnProduct.VISIBILITY) {
                    return (
                        <Table.Cell key={`product-row-col-cell-visibility-${index}`}>
                            {!isVisibilityChanging &&
                                // <StatusSelector
                                //     isActive={!product.is_disabled}
                                //     activeState="Visible"
                                //     inactiveState="Non visible"
                                //     onChange={changeVisibility}
                                // />
                                <StatusIndicator
                                    title={!product.is_disabled ? "Visible" : "Non visible"}
                                    variant={!product.is_disabled ? "success" : "default"}
                                />
                            }
                        </Table.Cell>
                    )
                } else if (col === columnProduct.ACTION) {
                    return (
                        <Table.Cell
                            key={`product-row-col-cell-action-${index}`}
                            onClick={(e) => e.stopPropagation()}
                            className="w-[32px]"
                        >
                            <Actionables forceDropdown={true} actions={getActions(product)} />
                        </Table.Cell>
                    )
                } else if (col === columnProduct.REMOVE) {
                    return (
                        <Table.Cell
                            key={`product-row-col-cell-remove-${index}`}
                            onClick={(e) => e.stopPropagation()}
                            className="w-[32px]">
                            <span onClick={() => toggle(product)} className="text-blue-70 inter-small-semibold cursor-pointer">Retirer</span>
                        </Table.Cell>
                    )
                } else if (col === columnProduct.PIM) {
                    return (
                        <Table.Cell key={`product-row-col-cell-prim-${index}`}>{DateHelper.dateToDDMMYYYY(product.pim_sync_at)}</Table.Cell>
                    )
                }
            })}
        </Table.Row>
    )
}