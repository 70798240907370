import DateHelper from "./utils/DateHelper";

export enum FlowName{
    PROCESS_AGENCIES = 'process_agencies',
    PROCESS_UNIVERSES = 'process_universes',
    PROCESS_PRODUCTS = 'process_products',
    PROCESS_CLIENTS = 'process_clients',
    PROCESS_BRANDS = 'process_brands',
    PROCESS_CATEGORIES = 'process_categories',
    PROCESS_INDEX_PRODUCTS = 'process_index_products',
    BUCKET_PRODUCTS = 'bucket_products',
}

export enum FlowStatus{
    STATUS_WAITING = "waiting",
    STATUS_RUNNING = "running",
    STATUS_SUCCESS = "success",
    STATUS_FAIL = "fail",
}

class Report {
    public total: number;
    public done?: number;
    public added?: number;
    public updated?: number;
    public ignored?: number;
    public skipped?: number;

    constructor(data: any){
        this.total = data.total;
        this.done = data.done;
        this.added = data.added;
        this.updated = data.updated;
        this.ignored = data.ignored;
        this.skipped = data.skipped;
    }
}

export class Flow{
    public id: number;
    public name: FlowName;
    public start_at: Date;
    public end_at?: Date;
    public status: FlowStatus;
    public message?: string;
    public url_file: string;
    public created_at: Date;
    public updated_at: Date;
    public report?: Report;

    constructor(data: any){
        this.id = data.id;
        this.name = data.name;
        this.start_at = new Date(data.start_at);
        this.end_at = data.end_at ? new Date(data.end_at) : undefined;
        this.status = data.status;
        this.message = data.message;
        this.url_file = data.url_file;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        if (data.report) {
            this.report = new Report(data.report)
        }
    }

    getName() {
        if (this.name == FlowName.PROCESS_AGENCIES) {
            return 'Flux - Agences'
        } else if (this.name == FlowName.PROCESS_CLIENTS) {
            return 'Flux - Clients'
        } else if (this.name == FlowName.PROCESS_PRODUCTS) {
            return 'Flux - Produits'
        } else if (this.name == FlowName.PROCESS_UNIVERSES) {
            return 'Flux - Univers'
        } else if (this.name == FlowName.PROCESS_BRANDS) {
            return 'Flux - Marques'
        } else if (this.name == FlowName.PROCESS_CATEGORIES) {
            return 'Flux - Catégories'
        } else if (this.name == FlowName.PROCESS_INDEX_PRODUCTS) {
            return 'Flux - Index Produits'
        } else if (this.name == FlowName.BUCKET_PRODUCTS) {
            return 'Bucket - Produits'
        } 
        else return this.name
    }
    
    getStatus() {
        if (this.status == FlowStatus.STATUS_WAITING) {
            return 'En cours'
        } else if (this.status == FlowStatus.STATUS_RUNNING) {
            return 'En cours'
        } else if (this.status == FlowStatus.STATUS_FAIL) {
            return 'Echec'
        } else if (this.status == FlowStatus.STATUS_SUCCESS) {
            return 'Succès'
        } else return 'En cours'
    }
    
    getStatusVariant() {
        if (this.status == FlowStatus.STATUS_WAITING) {
            return 'active'
        } else if (this.status == FlowStatus.STATUS_RUNNING) {
            return 'active'
        } else if (this.status == FlowStatus.STATUS_FAIL) {
            return 'default'
        } else if (this.status == FlowStatus.STATUS_SUCCESS) {
            return 'success'
        } else return 'default'
    }
    
    generateCsvName() {
        let name = ''
        if (this.name == FlowName.PROCESS_AGENCIES) {
            name = name + 'flux_agences_'
        } else if (this.name == FlowName.PROCESS_CLIENTS) {
            name = name + 'flux_clients_'
        } else if (this.name == FlowName.PROCESS_PRODUCTS) {
            name = name + 'flux_produits_'
        } else if (this.name == FlowName.PROCESS_UNIVERSES) {
            name = name + 'flux_univers_'
        } else if (this.name == FlowName.PROCESS_BRANDS) {
            name = name + 'flux_marques_'
        } else if (this.name == FlowName.PROCESS_CATEGORIES) {
            name = name + 'flux_catégories_'
        } else if (this.name == FlowName.PROCESS_INDEX_PRODUCTS) {
            name = name + 'flux_index_produits_'
        } else if (this.name == FlowName.BUCKET_PRODUCTS) {
            name = name + 'bucket_produits_'
        }
        name = name + `${DateHelper.dateToDDMMYYYY(this.start_at).replace('/','_')}`
        return name
    }
}