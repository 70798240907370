import React, {useCallback, useEffect, useState} from "react"
import actionables, {ActionType} from "../../../../components/fundamentals/actionables/actionables";
import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import BreadCrumb from "../../../../components/fundamentals/bread-crumb";
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon";
import Table from "../../../../components/fundamentals/table";
import TableContainer from "../../../../components/fundamentals/table-container";
import {DataPaginate} from "../../../../models/utils/DataPaginate";
import adminService from "../../../../services/admin.service";
import {Admin} from "../../../../models/Admin";
import ModalAdminUser from "./modal-admin-user/ModalAdminUser";
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon";
import EditIcon from "../../../../components/fundamentals/icons/edit-icon";
import Actionables from "../../../../components/fundamentals/actionables/actionables";
import useImperativeDialog from "../../../../hooks/use-imperative-dialog";
import useNotification from "../../../../hooks/use-notification";
import InfoIcon from "../../../../components/fundamentals/icons/info-icon";
import authService from "../../../../services/auth.service";


const UsersList = () => {

    const [dataPaginate, setDataPaginate] = useState<DataPaginate>();
    const [showAdminUserModal, setShowAdminUserModal] = useState(false);
    const [tmpSearch, setTmpSearch] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    async function fetchAdmin() {
        setIsLoading(true);
        setDataPaginate(await adminService.list(currentPage));
        setIsLoading(false);
    }
    
    useEffect(() => {
        fetchAdmin();
    }, [currentPage]);

    const actionables = [
        {
            label: "Nouvel utilisateur",
            onClick: () => setShowAdminUserModal(true),
            icon: (
                <span className="text-grey-90">
              <PlusIcon size={20}/>
            </span>
            ),
        },
    ]

    const postSubmit = useCallback(async () => {
        setShowAdminUserModal(false);

        await fetchAdmin();
    }, []);

    return (
        <>
            {showAdminUserModal &&
                <ModalAdminUser handleClose={() => setShowAdminUserModal(false)} postSubmit={postSubmit}/>
            }
            <div className="flex flex-col h-full">
                <div className="w-full flex flex-col grow">
                    <BreadCrumb
                        previousRoute="/settings"
                        previousBreadcrumb="Paramètres"
                        currentPage="Utilisateurs"
                    />
                    <BodyCard
                        title="Utilisateurs"
                        actionables={actionables}
                    >
                        <div className="flex grow  flex-col pt-2">
                            <TableContainer
                                numberOfRows={10}
                                isLoading={dataPaginate && dataPaginate?.data.length > 0 ? false : true}
                            >
                                <Table
                                    enableSearch={true}
                                    searchValue={tmpSearch}
                                    handleSearch={(term) => setTmpSearch(term)}
                                >
                                    <>
                                        <Table.Head>
                                            <Table.HeadRow>
                                                <Table.HeadCell>Nom</Table.HeadCell>
                                                <Table.HeadCell>Email</Table.HeadCell>
                                                <Table.HeadCell>Fonction</Table.HeadCell>
                                            </Table.HeadRow>
                                        </Table.Head>
                                        <Table.Body>
                                            {dataPaginate && dataPaginate?.data.length && dataPaginate.data.map((user: Admin) => (
                                                <UsersRow refreshList={fetchAdmin} user={user} key={`users-row-${user.id}`}></UsersRow>
                                            ))}
                                        </Table.Body>
                                    </>
                                </Table>
                            </TableContainer>

                        </div>
                    </BodyCard>

                </div>
            </div>
        </>
    )
}


interface TUsersRow {
    user: Admin;
    refreshList: () => Promise<void>
}

const UsersRow = ({user, refreshList}: TUsersRow) => {

    const [showAdminUserModal, setShowAdminUserModal] = useState<boolean>(false);

    const notificationToast = useNotification();

    const imperativeDialog = useImperativeDialog();

    const onDeleteAdminUser = useCallback(async () => {
        const hasAdminUserToBeDeleted = await imperativeDialog({
            heading: "Supprimer l'utilisateur",
            text: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
        });

        if (hasAdminUserToBeDeleted) {
            try {
                await adminService.deleteAdmin(user.id);

                await refreshList();

                notificationToast("Succès", "L'utilisateur a été supprimé", "success");
            } catch (e) {
                notificationToast("Erreur", "Veuillez réessayez", "error");
            }
        }

    }, [imperativeDialog, user]);

    const onResetAdminUserPassword = useCallback(async () => {
        const hasAdminUserPasswordToBeReset = await imperativeDialog({
            heading: "Réinitialiser le mot de passe",
            text: "Êtes-vous sûr de vouloir réinitialiser le mot de passe de cet utilisateur ?"
        })

        if (hasAdminUserPasswordToBeReset) {
            try {
                await authService.resetPassword(user.email);

                notificationToast(
                    "Succès",
                    "L'utilisateur va recevoir un email pour réinitialiser son mot de passe",
                    "success"
                );
            } catch (e: any) {
                notificationToast("Erreur", e.message, "error");
            }
        }

    }, [imperativeDialog, user]);

    const adminUserActions: ActionType[] = [
        {
            label: "Modifier",
            onClick: () => setShowAdminUserModal(true),
            icon: <EditIcon size={20}/>,
        },
        {
            label: "Supprimer",
            variant: "danger",
            onClick: onDeleteAdminUser,
            icon: <TrashIcon size={20} />,
        },
        {
            label: "Réinitialiser le mot de passe",
            onClick: onResetAdminUserPassword,
            icon: <InfoIcon size={20}/>
        }
    ];

    const postSubmit = useCallback(async () => {
        setShowAdminUserModal(false);

        await refreshList();
    }, [])

    return (
        <>
            {showAdminUserModal &&
                <ModalAdminUser
                    handleClose={() => setShowAdminUserModal(false)}
                    postSubmit={postSubmit}
                    adminUser={user}
                />
            }
            <Table.Row
                color={"inherit"}
                className="hover:bg-grey-5 cursor-pointer"
            >
                <Table.Cell>{user.firstname} {user.lastname}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.function}</Table.Cell>
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                    <Actionables forceDropdown={true} actions={adminUserActions}></Actionables>
                </Table.Cell>
            </Table.Row>
        </>
    );
};


export default UsersList

