import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BodyCard from "../../../../components/fundamentals/body-card/body-card";
import Button from "../../../../components/fundamentals/button";
import TableViewHeader from "../../../../components/fundamentals/custom-table-header";
import UploadIcon from "../../../../components/fundamentals/icons/upload-icon";
import CategoryTable from "../category-table/CategoryTable";
import ModalCategory from "./modal-category/ModalCategory";
import ModalImport from "./modal-import/ModalImport";
import ProductTable from "./product-table/ProductTable";

const VIEWS = ["produits", "catégories"];

export enum ETypeModalImport {
  CHARACTERISTICS = "caractéristiques",
  BLAKLADER = "caractéristiques blaklader",
  MIN_STOCK = "stock mini",
}

export default function ProductsPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [view, setView] = useState<any>("produits");
  const [showNewCategory, setShowNewCategory] = useState<boolean>(false);
  const [openModalImport, setOpenModalImport] = useState<ETypeModalImport | null>(null);

  const pushRoute = (view: string) => {
    navigate(`/products?view=${view}`)
  }
  
  useEffect(() => {
    if (searchParams.get('view')) {
      setView(searchParams.get('view'))
    }
  },[searchParams])

  const CurrentView = () => {
    switch (view) {
      case "produits":
        return <ProductTable />;
      default:
        return <CategoryTable />;
    }
  };

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          forceDropdown={false}
          customHeader={
            <TableViewHeader
              views={VIEWS}
              setActiveView={pushRoute}
              activeView={view}
            />
          }
          customActionable={
            <div className="flex space-x-2">
              <Button
                variant="secondary"
                size="small"
                onClick={() => setOpenModalImport(ETypeModalImport.MIN_STOCK)}
              >
                <UploadIcon size={20} />
                Import des stocks mini
              </Button>

              <Button
                variant="secondary"
                size="small"
                onClick={() => setOpenModalImport(ETypeModalImport.BLAKLADER)}
              >
                <UploadIcon size={20} />
                Import Blaklader
              </Button>
            </div>
          }
          className="h-fit"
        >
          <CurrentView />
        </BodyCard>
      </div>
      {showNewCategory && (
        <ModalCategory onClose={() => setShowNewCategory(false)} />
      )}
      <ModalImport
        open={openModalImport !== null}
        onClose={() => setOpenModalImport(null)}
        type={openModalImport!}
      />
    </div>
  );
}
