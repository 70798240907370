export class Document {

  ClientNom: string;
  ClientNumero: string
  DematDetails: string
  DematStatut: string
  DocumentDate: Date
  DocumentGuid: string;
  DocumentNumero: string
  EnvoiType: string
  SocieteCode: string

  //DATE "20230925"

  constructor(data: any) {
    this.ClientNom = data.ClientNom;
    this.ClientNumero = data.ClientNumero;
    this.DematDetails = data.DematDetails;
    this.DematStatut = data.DematStatut;

    const year = parseInt(data.DocumentDate.substring(0, 4), 10);
    const month = parseInt(data.DocumentDate.substring(4, 6), 10) - 1; // Soustrayez 1 car les mois dans JavaScript sont 0-indexés
    const day = parseInt(data.DocumentDate.substring(6, 8), 10);

    this.DocumentDate =  new Date(year, month, day);
    this.DocumentGuid = data.DocumentGuid;
    this.DocumentNumero = data.DocumentNumero;
    this.EnvoiType = data.EnvoiType;
    this.SocieteCode = data.SocieteCode;


  }
}
