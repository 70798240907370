import StatusIndicator from "../../../../../components/fundamentals/status-indicator";
import Table from "../../../../../components/fundamentals/table";
import { Order } from "../../../../../models/Order";
import { AgencieStrapi } from "../../../../../models/strapi/AgencieStrapi.model";
import DateHelper from "../../../../../models/utils/DateHelper";
import PriceHelper from "../../../../../models/utils/PriceHelper";

interface IProps {
  order: Order;
  index: number;
  showColClient?: boolean;
  agencies?: AgencieStrapi[];
}
export default function OrderRow({ order, index, showColClient = true, agencies }: IProps) {
  const getBgEntreprise = () => {
    if (index % 3 === 0) return "bg-orange-40";
    else if (index % 2 === 0) return "bg-pink-40";
    else return "bg-fuschia-40";
  };

  return (
    <Table.Row linkTo={`/orders/${order.id}`} color={"inherit"}>
      <Table.Cell>#{order.id}</Table.Cell>
      <Table.Cell>
        {DateHelper.dateToDDMMYYYYAndHM(order.created_at)}
      </Table.Cell>
      {showColClient && <Table.Cell>
        <div className="flex items-center">
          <div className="w-[24px] h-[24px]">
            <span
              className={`w-full h-full items-center justify-center overflow-hidden select-none rounded-circle ${getBgEntreprise()}`}
            >
              <span className="w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded-circle inter-small-semibold">
                {order.client.name.charAt(0)}
              </span>
            </span>
          </div>
          <span className="ml-2">{order.client.name}</span>
        </div>
      </Table.Cell> }
      <Table.Cell>{order?.getPickUpAddress(agencies ?? []) ?? "-"} </Table.Cell>
      <Table.Cell>{PriceHelper.price2Digits(order.total_amount)} €</Table.Cell>
      {/* <Table.Cell>
        <StatusIndicator
          title={order.status == "request" ? "Actif" : "Non actif"}
          variant={order.status == "request" ? "active" : "default"}
        />
      </Table.Cell> */}
    </Table.Row>
  );
}
