import React from "react"
import DecorationAuthBottom from "./DecorationAuthBottom"
import DecorationAuthTop from "./DecorationAuthTop"

const TemplateLogin = ({ children }: any) => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="absolute top-0 right-0">
      </div>
      <div className="absolute bottom-0 left-0">
      </div>

      <div className="grid grid-cols-1 grid-rows-1 min-h-screen">
        <div
          className="flex flex-col items-center bg-blue-70"
          /*style={{
            background: "linear-gradient(73.29deg, #7C53FF 0%, #F796FF 100%)",
          }}*/
        >
          {children}
          <div className="text-grey-0 inter-base-regular pb-12">
            WebexpR <span>&#183;</span>{" "}
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="mailto:contact@webexpr.fr"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateLogin
