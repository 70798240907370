import NotFound from "../components/NotFound/NotFound";
import ProductsPage from "../pages/logged/products/products-page/ProductsPage";
import Login from "../pages/not-logged/login/login";
import BaseTemplate from "../components/templates/BaseTemplate/BaseTemplate";
import { createBrowserRouter } from "react-router-dom";
import ProductDetailPage from "../pages/logged/products/product-detail-page/ProductDetailPage";
import OrdersPage from "../pages/logged/orders/orders-page/OrdersPage";
import OrderDetailPage from "../pages/logged/orders/order-detail-page/OrderDetailPage";
import CategoryDetailPage from "../pages/logged/products/category-detail-page/CategoryDetailPage";
import PromotionsPage from "../pages/logged/promotions/promotion-page/PromotionsPage";
import ClientsPage from "../pages/logged/clients/clients-page/ClientsPage";
import ClientDetailPage from "../pages/logged/clients/client-detail-page/ClientDetailPage";
import SettingsPage from "../pages/logged/settings/SettingsPage";
import PersonalInformation from "../pages/logged/settings/personal-information";
import UsersList from "../pages/logged/settings/users";
import FlowsPage from "../pages/logged/settings/flows/flows-page/FlowsPage";
import DashboardPage from "../pages/logged/dashboard/DashboardPage";
import PasswordReset from "../pages/not-logged/auth/password-reset/PasswordReset";
import ValidateAccount from "../pages/not-logged/auth/validate-account/ValidateAccount";
import AskPasswordReset from "../pages/not-logged/auth/ask-password-reset/AskPasswordReset";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseTemplate />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <OrdersPage />,
      },
      {
        path: "orders",
        element: <OrdersPage />,
      },
      {
        path: "orders/:id",
        element: <OrderDetailPage />,
      },
      {
        path: "products",
        element: <ProductsPage />,
      },
      {
        path: "products/:id",
        element: <ProductDetailPage />,
      },
      {
        path: "categories/:id",
        element: <CategoryDetailPage />,
      },
      {
        path: "customers",
        element: <ClientsPage />,
      },
      {
        path: "customers/:id",
        element: <ClientDetailPage />,
      },
      {
        path: "promotions",
        element: <PromotionsPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          {
            path: 'personal-information',
            element: <PersonalInformation /> 
          },

          {
            path: 'users',
            element: <UsersList/> 
          },

          {
            path: "flows",
            element: <FlowsPage />,
          },
        ]
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/auth/password-reset/:token",
    element: <PasswordReset />
  },
  {
    path: "/auth/validate-account/:token",
    element: <ValidateAccount />
  },
  {
    path: "/auth/password-reset",
    element: <AskPasswordReset />
  }
]);

export default router;
