import axios from "axios";
import { Price } from "../models/Price";
import { Product } from "../models/Product";
import { ProductDocument } from "../models/ProductDocument";
import { Stock } from "../models/Stock";
import { DataPaginate } from "../models/utils/DataPaginate";
import { IFilter } from "../models/utils/filter/FilterType.interface";
import { convertFiltersToQuery, getParamsUrl } from "./utils/serviceHelper";

function importCSV(file: File, type: string){
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post(`${process.env.REACT_APP_API_URL}import/${type}/csv`, formData )
      .then((response) =>response.data);
}

export default {
    importCSV
}
