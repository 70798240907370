import { TimeTableAgencieStrapi } from "./TimeTableAgenciesStrapi";

export class AgencieStrapi{
    id: number;
    name: string;
    agency_id: number;
    city: string;
    address: string;
    postal_code: string;
    phone_number: string;
    enable: boolean;
    division_code: string;
    display_name: string;
    is_platform: boolean;
    emails_notification: boolean;
    pickup_code: string;
    image_url: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    latitude: number;
    longitude: number;
    timetable_agencies: TimeTableAgencieStrapi[] = [];
    slug: string;

    constructor(data: any){
       this.id = data.id;
       this.name = data.attributes.name;
       this.agency_id = data.attributes.agency_id;
       this.city = data.attributes.city;
       this.address = data.attributes.address;
       this.postal_code = data.attributes.postal_code;
       this.phone_number = data.attributes.phone_number;
       this.enable = data.attributes.enable;
       this.division_code = data.attributes.division_code;
       this.display_name = data.attributes.display_name;
       this.is_platform = data.attributes.is_platform;
       this.emails_notification = data.attributes.emails_notification;
       this.pickup_code = data.attributes.pickup_code;
       this.image_url = data.attributes.image.data ? `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${data.attributes.image.data.attributes.url}` : '';
       this.createdAt = new Date(data.attributes.createdAt);
       this.updatedAt = new Date(data.attributes.updatedAt);
       this.publishedAt = new Date(data.attributes.publishedAt);
       this.latitude = data.attributes.latitude;
       this.longitude = data.attributes.longitude;
       if(data.attributes.Opening_Hours){
            this.timetable_agencies = data.attributes.Opening_Hours.map((item: any) => new TimeTableAgencieStrapi(item))
        }
        this.slug = data.attributes.slug;
    }

    isOpen(){
        const timetables = this.timetable_agencies.filter(item => item.day === this.getDayName(new Date().getDay()));
        const now = new Date();

        if(timetables.length === 0){
            return false;
        }

        return timetables.find(item => {
            const start = new Date();
            start.setHours(item.start_at.getHours());
            start.setMinutes(item.start_at.getMinutes());
            start.setSeconds(item.start_at.getSeconds());
            const end = new Date();
            end.setHours(item.end_at.getHours());
            end.setMinutes(item.end_at.getMinutes());
            end.setSeconds(item.end_at.getSeconds());

            return now >= start && now <= end;
        }) != null;
    }

    private getDayName(day: number){
        switch(day){
            case 0:
                return 'sunday'
            case 1:
                return 'monday'
            case 2:
                return 'tuesday'
            case 3:
                return 'wednesday'
            case 4:
                return 'thursday'
            case 5:
                return 'friday'
            case 6:
                return 'saturday'
        }
    }

    getFullAddress(){
        return `${this.address ? this.address.replace('\n', '') : ''} / ${this.postal_code ? this.postal_code.replace('\n', '') : ''} ${this.city || ''}`
    }

}
