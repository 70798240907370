import {useEffect, useState} from "react";
import TemplateLogin from "../../../components/templates/TemplateLogin/TemplateLogin";
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import SigninInput from "../../../components/form/input-signin";
import Button from "../../../components/fundamentals/button";
import authService from "../../../services/auth.service";
import LogoIcon from "../../../components/fundamentals/icons/logo-icon";
import SignInWithGoogle from "./SignInWithGoogle";
import scriptService from "../../../services/script.service";
import thirdpartyService from "../../../services/thirdparty.service";

type FormValues = {
  email: string
  password: string
}

export default function Login(){
    const [isInvalidLogin, setIsInvalidLogin] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState<boolean>(false);
    const [googleSignInErrorMessage, setGoogleSignInErrorMessage] = useState<string>("");

    const { register, handleSubmit, reset } = useForm<FormValues>()
    const navigate = useNavigate()

     const onSubmit = async (values: FormValues) => {
        setIsLoading(true);
        setIsInvalidLogin(false);
        try{
            await authService.authenticate(values);
            setIsLoading(false);
            navigate('/');
        } catch(e){
            reset();
            setIsInvalidLogin(true);
            setIsLoading(false);
        }
        
    }

    const handleGoogleSignInResponse = async (response: any) => {
        setGoogleSignInErrorMessage("");

        setIsGoogleSignInLoading(true);

        try {
            await authService.loginWithGoogle(response.credential);

            navigate('/');
        } catch (e: any) {
            setIsGoogleSignInLoading(false);

            if (e.status) {
                setGoogleSignInErrorMessage(e.message)
            }
        }
    }

    useEffect(() => {
        (async () => {
            await scriptService.insertScript('head',
                {
                    src: "https://accounts.google.com/gsi/client",
                    async: true
                }
            )

            thirdpartyService.renderGoogleSignInButton(handleGoogleSignInResponse)
        })();
    }, []);
    
    return (
        <TemplateLogin> 
            <div className="flex h-full w-full items-center justify-center">
                <div
                className={
                    "flex min-h-[500px] w-[640px] bg-grey-0 rounded-rounded justify-center transition-['min-height'] duration-300"
                }
                >
                    <div className="flex flex-col pt-12 w-full px-[120px] items-center">
                        
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col items-center">

                            <LogoIcon />
                            <span className="inter-2xlarge-semibold mt-4 text-grey-90">
                                Bienvenue
                            </span>
                            <span className="inter-base-regular text-grey-50 mb-xlarge">
                                Connectez-vous à votre compte ci-dessous
                            </span>
                            <SigninInput
                                placeholder="Entrez votre email..."
                                {...register("email", { required: true })}
                                autoComplete="email"
                            />
                            <SigninInput
                                placeholder="Mot de passe..."
                                type={"password"}
                                {...register("password", { required: true })}
                                autoComplete="current-password"
                            />
                            {isInvalidLogin && (
                                <span className="text-rose-50 w-full mt-2 inter-small-regular">
                                    Les identifiants sont incorrect !
                                </span>
                            )}
                            <Button
                                className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                                variant="primary"
                                size="large"
                                type="submit"
                                loading={isLoading}
                            >
                                Continuer
                            </Button>
                            {/*<SignInWithGoogle
                                isGoogleSignInLoading={isGoogleSignInLoading}
                                googleSignInErrorMessage={googleSignInErrorMessage}
                            />*/}
                            <span
                                className="inter-small-regular text-grey-50 mt-8 cursor-pointer"
                                onClick={() => navigate('/auth/password-reset')}
                            >
                                Mot de passe oublié
                            </span>
                        </div>
                    </form>
                    
                    </div>
                </div>
            </div>
        </TemplateLogin>

    )
}