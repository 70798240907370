import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Actionables, {
  ActionType,
} from "../../../../components/fundamentals/actionables/actionables";
import BackButton from "../../../../components/fundamentals/back-button";
import Section from "../../../../components/fundamentals/section";
import Spinner from "../../../../components/fundamentals/spinner";
import NotFound from "../../../../components/NotFound/NotFound";
import { Order, ProductOrder } from "../../../../models/Order";
import ordersService from "../../../../services/orders.service";
import DateHelper from "../../../../models/utils/DateHelper";
import { Client } from "../../../../models/Client";
import agencesService from "../../../../services/agences.service";
import { Agency } from "../../../../models/Agency";
import DetailsIcon from "../../../../components/fundamentals/icons/details-icon";
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon";
import PriceHelper from "../../../../models/utils/PriceHelper";

type Comment = {
  date: number;
  value: string;
  email: string;
};

export default function OrderDetailPage() {
  let { id } = useParams();
  const [order, setOrder] = useState<Order>();
  const [clientAgency, setClientAgency] = useState<Agency>(new Agency({}));
  const [loading, setLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<Array<Comment>>([]);
  const refCommentInput = useRef<any>();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchOrder() {
      try {
        setLoading(true);
        if (id) {
          const order = await ordersService.find(id);

          setOrder(order);

          setClientAgency(await agencesService.find(order.client.agencie_id));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    fetchOrder();
  }, [id]);

  const actionsClient: ActionType[] = [
    {
      label: "Voir la fiche client",
      onClick: navigateToDetailClient,
      icon: <DetailsIcon size={20} />,
    },
  ];

  function navigateToDetailClient() {
    if (order && order?.client.id) {
      navigate("/customers/" + order.client.id);
    }
  }

  const onEnterComment = () => {
    if (refCommentInput.current.value !== "") {
      setComments([
        ...comments,
        {
          date: new Date().getTime(),
          value: refCommentInput.current.value,
          email: "admin@webexpr.fr",
        },
      ]);
      refCommentInput.current.value = "";
    }
  };

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        {!loading && order && (
          <div className="pb-5xlarge">
            <BackButton
              path="/orders"
              label="Retour aux commandes"
              className="mb-xsmall"
            />
            <div className="grid grid-cols-12 gap-x-base">
              <div className="col-span-12 flex flex-col gap-y-xsmall">
                <Section title={`Commande #${order.id}`} forceDropdown>
                  <h3 className="inter-small-regular pt-1.5 text-grey-50">
                    {DateHelper.dateToDDMMYYYYLong(order.created_at)}
                  </h3>
                  <Details
                    datas={[
                      {
                        label: "Référence",
                        value: order.reference,
                      },
                      {
                        label: "Commentaire",
                        value: order.comment ? order.comment : "Aucun commentaire",
                      },
                    ]}
                  />
                </Section>

                <Section title={"Résumé"} forceDropdown>
                  <DetailsProducts
                    datas={
                      order.product_orders_with_product?.length > 0
                        ? order.product_orders_with_product
                        : []
                    }
                  />
                </Section>
                <Section title={"Client"} forceDropdown actions={actionsClient}>
                  <DetailsClient client={order.client} agency={clientAgency} />
                </Section>
              </div>
            </div>
          </div>
        )}
        {loading && <Spinner size="large" variant="secondary" />}
        {!loading && !order && <NotFound />}
      </div>
    </div>
  );
}

type DetailDatasType = {
  label: string;
  value: string;
};
type DetailsProps = {
  datas: Array<DetailDatasType>;
};

const Details = ({ datas }: DetailsProps) => {
  return (
    <div className="flex flex-col mt-large grow">
      <div className="flex mt-6 space-x-6 divide-x">
        {datas.map((data: DetailDatasType, index: number) => (
          <div
            key={`details-command-${data.label}-${index}`}
            className={`flex flex-col ${index > 0 ? "pl-6" : ""}`}
          >
            <div className="inter-smaller-regular text-grey-50 mb-1">
              {data.label}
            </div>
            <span className="text-grey-90 gap-x-1 flex items-center">
              {data?.value ?? "-"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

type DetailProductProps = {
  datas: Array<ProductOrder>;
};

const DetailsProducts = ({ datas }: DetailProductProps) => {
  const navigate = useNavigate();

  const getTotalOrder = () => {
    return datas.reduce((acc, curr) => acc + curr.quantity * curr.amount, 0);
  };
  return (
    <div className="flex flex-col mt-large grow">
      {datas.map((productOrder: ProductOrder, index: number) => (
        <div key={productOrder.product_id} className="flex justify-between mb-1 h-[64px] py-2 mx-[-5px] px-[5px] hover:bg-grey-5 rounded-rounded cursor-pointer">
          <React.Fragment key={`details-command-${productOrder.id}-${index}`}>
            <div
              className="flex space-x-4 justify-center items-center"
              onClick={() =>
                productOrder.product_id &&
                navigate(
                  "/products/" + productOrder.product_id + "?from=orders"
                )
              }
            >
              <div className="flex h-[48px] w-[36px] rounded-rounded overflow-hidden">
                <img
                  src={
                    productOrder.product.image_url
                      ? productOrder.product.image_url
                      : "/images/placeholder-product.svg"
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/placeholder-product.svg";
                  }}
                  alt={`Alt`}
                  className="object-cover"
                />
              </div>
              <span className="font-normal text-gray-900 overflow-ellipsis overflow-hidden whitespace-nowrap">
                {productOrder.product.name} ({productOrder.product.code_product})
              </span>
            </div>
            <div className="flex items-center">
              <div className="flex small:space-x-2 medium:space-x-4 large:space-x-4 mr-8">
                <div className="inter-base-regular text-grey-50">
                  {PriceHelper.price2Digits(productOrder.amount)} €
                </div>
                <div className="inter-base-regular text-grey-50">
                  x{productOrder.quantity}
                </div>
              </div>
              <div className="flex small:space-x-2 medium:space-x-4 large:space-x-2">
                <div className="inter-base-regular text-grey-50">
                  {PriceHelper.price2Digits(productOrder.amount * productOrder.quantity)} €
                </div>
                <div className="inter-base-regular text-grey-50">EUR</div>
              </div>
            </div>
          </React.Fragment>
        </div>
      ))}
      <div className="flex justify-between mt-10 items-center">
        <div className="flex flex-col">
          <div className="text-grey-90 inter-small-semibold">Total</div>
        </div>
        <div className="flex">
          <div className="text-grey-90 inter-xlarge-semibold">
            €{PriceHelper.price2Digits(getTotalOrder())}
          </div>
        </div>
      </div>
    </div>
  );
};

type DetailsClientProps = {
  client: Client;
  agency: Agency;
};

const DetailsClient = ({ client, agency }: DetailsClientProps) => {
  return (
    <div className="flex flex-col mt-large grow">
      <div className="flex w-full space-x-4 items-center">
        <div className="flex w-[40px] h-[40px] ">
          <span className="w-full h-full items-center justify-center overflow-hidden select-none rounded-circle bg-fuschia-40">
            <span className="w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded-circle inter-large-semibold">
              {client.name.charAt(0)}
            </span>
          </span>
        </div>
        <div>
          <h1 className="inter-large-semibold text-grey-90">{client.name}</h1>
          <span className="inter-small-regular text-grey-50">
            {`${agency.city}, France`}
          </span>
        </div>
      </div>
      <Details
        datas={[
          {
            label: "Contact",
            value: agency.emails_notification,
          },
          {
            label: "Adresse de retrait",
            value: agency.address,
          },
        ]}
      />
    </div>
  );
};

type CommentOrderProps = {
  comment: Comment;
  deleteComment: (comment: Comment) => void;
};

const CommentOrder = ({ comment, deleteComment }: CommentOrderProps) => {
  const actionsComment: ActionType[] = [
    {
      label: "Supprimer",
      onClick: () => deleteComment(comment),
      icon: <TrashIcon size={20} />,
      variant: "danger",
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-xsmall">
          <div className="h-5 w-5 text-grey-50">
            <span className="w-full h-full items-center justify-center overflow-hidden select-none rounded-circle bg-violet-60">
              <span className="w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded-circle inter-small-semibold uppercase">
                {comment.email.charAt(0)}
              </span>
            </span>
          </div>
          <div className="inter-small-semibold">{comment.email}</div>
        </div>
        <Actionables actions={actionsComment} forceDropdown />
      </div>
      <div className="flex gap-x-xsmall">
        <div className="w-5 flex justify-center pt-base">
          <div className="w-px min-h-[24px]"></div>
        </div>
        <div className="w-full inter-small-regular">
          <div className="flex items-center">
            <span data-state="closed">
              <div className="text-grey-50 inter-small-regular">
                {DateHelper.formatThereIsDate(comment.date)}
              </div>
            </span>
          </div>
          <div className="mt-small w-full pb-base">
            <div className="rounded-2xl px-base py-base bg-violet-5 text-violet-90">
              {comment.value}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
